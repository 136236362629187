import { Can, TangCan } from "./TextFormat"

// Function to get the CSS class for text color based on Can value
export const getCanColorClass = (content) => {
  if (!content) {
    console.error('Content is undefined or null')
    return 'text-white' // default class when content is not available
  }
  // Convert the content to lowercase for case-insensitive comparison
  const lowerContent = content.toLowerCase()
  // Find the Can key that matches the lowercase content
  const canKey = Object.keys(Can).find(key => key.toLowerCase() === lowerContent)
  // Use the found key to get the corresponding NguHanh value, if a match was found
  const colorClass = canKey ? Can[canKey] : 'text-white'
  return colorClass
}

//function for diachi
export const getDiachiDetails = (data) => {
  if (!data) {
    console.error('data is undefined or null')
    return null // or some default value that fits your function's expected return type
  }
  // Convert the target word to lowercase
  data = data.trim().toLowerCase()

  let diachiDetails = null

  // Iterate over each object in the TangCan array
  for (const item of TangCan) {
    // Convert diachi value to lowercase for comparison
    const diachiLower = item.diachi.toLowerCase()
    // Check if the diachi value matches the target word
    if (diachiLower === data) {
      // If there's a match, assign the diachi_color value along with text_1, text_2, and text_3
      diachiDetails = {
        diachi_color: item.diachi_color,
        text_1: item.text_1,
        text_2: item.text_2,
        text_3: item.text_3
    }
      // Break the loop since we found a match
      break
    }
  }
  // Return diachiDetails value
  return diachiDetails
}
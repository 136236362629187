import { SidebarContent } from "./SidebarContent"
import { Separator } from "@/components/ui/separator"

export function Sidebar () {
  return (
    <div className="lg:fixed w-full lg:max-w-[200px] lg:h-screen lg:border-r bg-white">
      <div>
        <img src="https://phongthuytutam.s3.ap-southeast-2.amazonaws.com/TienDat.svg" alt="img" className="w-[120px] mb-4"/>
        <p className="text-gray-500 text-[15px] mb-6">
          Hiểu mình, hiểu người.<br/> Lợi mình, lợi người
        </p>
      </div>
      <Separator className="w-full lg:w-20"/>
      <div className="overflow-x-auto">
        <SidebarContent />
      </div>
    </div>
  )
}
/* eslint-disable react/prop-types */

export function Value ({ description, title, svg }) {
  return (
    <div className="p-6 bg-white border rounded-md w-full">
      <p className="text-base text-gray-800 mb-4 h-40 lg:h-60">
        {description}
      </p>
      <div className="flex justify-between items-end w-full">
        <h4 className="text-2xl font-noto text-gray-900">{title}</h4>
        {svg}
      </div>
    </div>
  )
}
/* eslint-disable react/prop-types */
import { Badge } from "@/components/ui/badge"
import { Link } from "react-router-dom"
import { LazyLoadImage } from "react-lazy-load-image-component"

export function Article ({ slug, img, title, meta, tag, date }) {
  // Convert ISO date string to Date object
  const formattedDate = new Date(date).toLocaleDateString('vi-VN', {
    day: 'numeric',
    month: 'long',
    year: 'numeric'
  })

  return (
    <Link to={`/bai-viet/${slug}`}>
      <div 
      className="border border-gray-200 w-full h-full max-h-[400px] rounded-lg hover:border-gray-300 hover:shadow-[0_0px_0px_5px_rgba(203,213,225,0.25)]"
      >
        <LazyLoadImage
          src={img} alt="thumbnail"
          className="rounded-md w-full" 
        />
        <div className="p-4">
          <h3 className="text-lg font-medium mb-2 text-gray-900 font-noto2">
            {title}
          </h3>
          <p className="text-sm text-gray-500 mb-4 overflow-hidden h-10" >
            {meta}
          </p>
          <div className="flex justify-between items-center">
            <Badge variant="secondary">
              {tag}
            </Badge>
            <p className="text-xs font-medium text-gray-500">
              {formattedDate}
            </p>
          </div>
        </div>
      </div>
    </Link>
  )
}
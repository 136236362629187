// can tang co 12 truong hop
const NguHanh = {
  Moc: 'text-green-600',
  Thuy:'text-zinc-900',
  Hoa: 'text-red-600',
  Tho: 'text-yellow-600',
  Kim: 'text-slate-400',
};


export const Can = {
  giáp: NguHanh.Moc,
  ất: NguHanh.Moc,
  bính: NguHanh.Hoa,
  đinh: NguHanh.Hoa,
  mậu: NguHanh.Tho,
  kỷ: NguHanh.Tho,
  canh: NguHanh.Kim,
  tân: NguHanh.Kim,
  nhâm: NguHanh.Thuy,
  quý: NguHanh.Thuy,
}

export const TangCan = [
  {
    diachi: 'Tý',
    diachi_color: NguHanh.Thuy,
    text_1: Can.quý,
    text_2: '',
    text_3: '',
  },
  {
    diachi: 'Sửu',
    diachi_color: NguHanh.Tho,
    text_1: Can.kỷ,
    text_2: Can.quý,
    text_3: Can.tân,
  },
  {
    diachi: 'Dần',
    diachi_color: NguHanh.Moc,
    text_1: Can.giáp,
    text_2: Can.bính,
    text_3: Can.mậu,
  },
  {
    diachi: 'Mão',
    diachi_color: NguHanh.Moc,
    text_1: Can.ất,
    text_2: '',
    text_3: '',
  },
  {
    diachi: 'Thìn',
    diachi_color: NguHanh.Tho,
    text_1: Can.mậu,
    text_2: Can.ất,
    text_3: Can.quý,
  },
  {
    diachi: 'Tị',
    diachi_color: NguHanh.Hoa,
    text_1: Can.bính,
    text_2: Can.mậu,
    text_3: Can.canh,
  },
  {
    diachi: 'Ngọ',
    diachi_color: NguHanh.Hoa,
    text_1: Can.đinh,
    text_2: Can.kỷ,
    text_3: '',
  },
  {
    diachi: 'Mùi',
    diachi_color: NguHanh.Tho,
    text_1: Can.kỷ,
    text_2: Can.đinh,
    text_3: Can.ất,
  },
  {
    diachi: 'Thân',
    diachi_color: NguHanh.Kim,
    text_1: Can.canh,
    text_2: Can.nhâm,
    text_3: Can.mậu,
  },
  {
    diachi: 'Dậu',
    diachi_color: NguHanh.Kim,
    text_1: Can.tân,
    text_2: '',
    text_3: '',
  },
  {
    diachi: 'Tuất',
    diachi_color: NguHanh.Tho,
    text_1: Can.mậu,
    text_2: Can.tân,
    text_3: Can.đinh,
  },
  {
    diachi: 'Hợi',
    diachi_color: NguHanh.Thuy,
    text_1: Can.nhâm,
    text_2: Can.giáp,
    text_3: '',
  },
]
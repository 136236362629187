/* eslint-disable react/prop-types */
import { HeadingLargeMobile } from "@/components/common/Heading"
import { Sidebar } from "./elements/Sidebar"
import { Articles } from "./elements/Articles"
import { useState, useEffect } from "react"
import axios from "axios"
import { useProgress } from "@/lib/useProgress"
import { BASE_URL } from "@/config"

export function Blog ({setProgress}) {
  useProgress(setProgress)

  const [posts, setPosts] = useState([])

  useEffect (() => {
    const fetchArticles = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/posts`)
        // Set the fetched articles in state
        setPosts(response.data)
        console.log(response.data)
      } catch (error) {
        console.error('Error fetching articles:', error)
      }
    }
    fetchArticles()
  }, [])

  return(
    <div className="md:ml-[88px] h-screen">
      <div className="mx-auto max-w-[1392px] w-full h-screen p-4">
        <HeadingLargeMobile size="text-2xl" text="Tất cả bài viết" align="text-left" visibility="block lg:hidden" margin="mb-4"/>
        <div className="flex flex-col lg:flex-row">
          <Sidebar />
          <Articles
          heading="Tất cả bài viết"
          data={posts}
          />
        </div>
      </div>
    </div>
  )
}
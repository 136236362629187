/* eslint-disable react/prop-types */
import Lottie from "lottie-react"
import YinYang from "@/animation/YinYang.json"
import { Button } from "@/components/ui/button"
import { NavLink } from "react-router-dom"
import { useProgress } from "@/lib/useProgress"

export function Home ({ setProgress }) {
  const style = {
    height: 280,
  }

  useProgress(setProgress)

  return(
    <div className="flex flex-col justify-center items-center h-screen px-4 md:pl-[88px] md:pr-0">
      <Lottie animationData={YinYang} loop={true} style={style} />
      <h1 className="text-4xl text-center font-normal font-noto text-gray-950 mt-6 mb-3">
        Phong Thuỷ Từ Tâm
      </h1>
      <p className="text-center text-sm text-gray-500 mb-6">
        Chào mừng bạn đã đến với cộng đồng <br/> <span className="italic font-medium font-noto2 text-lime-700">&quot;Tiến Đạt Phong Thuỷ&quot;</span>
      </p>
      <div className="w-full md:w-[320px] flex flex-col md:flex-row gap-2">
        <NavLink className="w-full" to="/an-la-so-bat-tu">
          <Button className="w-full">
              An Lá Số
          </Button>
        </NavLink>
        <NavLink className="w-full" to="https://zalo.me/g/ewycfe515" target="_blank">
          <Button className="w-full" variant="outline">
            Cộng đồng
          </Button>
        </NavLink>
      </div>
    </div>
  )
}
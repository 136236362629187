import {z} from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { Input } from "@/components/ui/input"
import { Button } from "@/components/ui/button"
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select"
import { useToast } from "@/components/ui/use-toast"
import { ReloadIcon } from "@radix-ui/react-icons"
// eslint-disable-next-line no-unused-vars
import { Label } from "@/components/ui/label"
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group"
import axios from 'axios'
import { useNavigate } from "react-router-dom"
import { useState, useContext } from 'react'
import { FormDataContext } from "@/context/DataContext"
import { BASE_URL } from "@/config"

const VALUES = ["male", "female"]

const formSchema = z.object({
  name: z.string({required_error: "Vui lòng nhập tên"}).max(50),
  year: z.string({required_error: "Vui lòng chọn năm sinh"}),
  month: z.string({required_error: "Vui lòng chọn tháng sinh"}),
  day: z.string({required_error: "Vui lòng chọn ngày sinh"}),
  hour: z.string({required_error: "Vui lòng chọn giờ sinh"}),
  gender: z.enum(VALUES)
})

export function AnLaSoForm () {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { setFormData } = useContext(FormDataContext); // Access setFormData function from context
  const navigate = useNavigate()
  
  const form= useForm({
    resolver: zodResolver(formSchema),
    defaultValues: { name: "" }
  })

  const { toast } = useToast()

  const onSubmit = async (data) => {
    setIsSubmitting(true)
    // Parse year, month, day, and hour as integers
    data.year = parseInt(data.year);
    data.month = parseInt(data.month);
    data.day = parseInt(data.day);
    data.hour = parseInt(data.hour);
    console.log(data)

    try {
      const response = await axios.post(`${BASE_URL}/api/data`, {
        name: data.name,
        year: data.year,
        month: data.month,
        day: data.day,
        hour: data.hour,
        gender: data.gender
      })
      //console.log('Response from server:', response.data)
      // Set the retrieved data in the context
      setFormData(response.data);
      // Redirect to the desired URL after successful form submission
      navigate("/an-la-so-bat-tu/ket-qua", { state: { dataInput: data } })
      toast({
        title: "Đã gửi yêu cầu!",
        description: "...",
      });
    } catch (error) {
      //console.error('Error:', error);
      // Handle error if needed
      toast({
        variant: "destructive",
        title: "An lá số thất bại!",
        description: "Đang có sự cố một chút. Vui lòng an lại lá số."
      });
    } finally {
      setIsSubmitting(false); // Set isSubmitting back to false after form submission is completed
    }
  }

  // Function to generate years from 1990 to current year
  function generateYears() {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let year = 1990; year <= currentYear; year++) {
      years.push(year.toString());
    }
    return years;
  }

  // Function to generate months from 1 to 12
  function generateMonths() {
    const months = [];
    for (let month = 1; month <= 12; month++) {
      months.push(month.toString());
    }
    return months;
  }

  // Function to generate days from 1 to 31
  function generateDays() {
    const days = [];
    for (let day = 1; day <= 31; day++) {
      days.push(day.toString());
    }
    return days;
  }

  return(
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6 w-full">
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem className="w-full flex flex-col">
              <FormLabel>Tên của bạn</FormLabel>
              <FormControl>
                <Input placeholder="Nhập tên.." {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="year"
          render={({ field }) => (
            <FormItem className="w-full flex flex-col">
              <FormLabel>Năm sinh</FormLabel>
              <Select onValueChange={field.onChange} defaultValue={field.value}>
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder="Chọn năm sinh" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  {generateYears().map(year => (
                    <SelectItem key={year} value={year}>{year}</SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="month"
          render={({ field }) => (
            <FormItem className="w-full flex flex-col">
              <FormLabel>Tháng sinh</FormLabel>
              <Select onValueChange={field.onChange} defaultValue={field.value}>
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder="Chọn tháng sinh" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  {generateMonths().map(month => (
                    <SelectItem key={month} value={month}>{month}</SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="day"
          render={({ field }) => (
            <FormItem className="w-full flex flex-col">
              <FormLabel>Ngày sinh</FormLabel>
              <Select onValueChange={field.onChange} defaultValue={field.value}>
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder="Chọn ngày sinh" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  {generateDays().map(day => (
                    <SelectItem key={day} value={day}>{day}</SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="hour"
          render={({ field }) => (
            <FormItem className="w-full flex flex-col">
              <FormLabel>Giờ sinh</FormLabel>
              <Select onValueChange={field.onChange} defaultValue={field.value}>
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder="Chọn giờ sinh" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  {hours.map(hour => (
                    <SelectItem key={hour.value} value={hour.value}>{hour.label}</SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="gender"
          render={({ field }) => (
            <FormItem className="space-y-3">
              <FormLabel>Chọn giới tính</FormLabel>
              <FormControl>
                <RadioGroup
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                  className="flex flex-row items-center gap-6"
                >
                  <FormItem className="flex items-center space-x-2 space-y-0">
                    <FormControl>
                      <RadioGroupItem value="male" />
                    </FormControl>
                    <FormLabel className="font-normal">
                      Nam
                    </FormLabel>
                  </FormItem>
                  <FormItem className="flex items-center space-x-2 space-y-0">
                    <FormControl>
                      <RadioGroupItem value="female" />
                    </FormControl>
                    <FormLabel className="font-normal">
                      Nữ
                    </FormLabel>
                  </FormItem>
                </RadioGroup>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <Button className="w-full" type="submit" disabled={isSubmitting}>
          { isSubmitting ? (
            <>
              <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
              Đang an Lá Số
            </>
          ) : ("An Lá Số") }
        </Button>
      </form>
    </Form>
  )
}

// eslint-disable-next-line react-refresh/only-export-components
export const hours = [
  { label: "Tí (23h - 1h)", value: "0" },
  { label: "Sửu (1h - 3h)", value: "2" },
  { label: "Dần (3h - 5h)", value: "4" },
  { label: "Mão (5h - 7h)", value: "6" },
  { label: "Thìn (7h - 9h)", value: "8" },
  { label: "Tị (9h - 11h)", value: "10" },
  { label: "Ngọ (11h - 13h)", value: "12" },
  { label: "Mùi (13h - 15h)", value: "14" },
  { label: "Thân (15h - 17h)", value: "16" },
  { label: "Dậu (17h - 19h)", value: "18" },
  { label: "Tuất (19h - 21h)", value: "20" },
  { label: "Hợi (21h - 23h)", value: "22" }
]

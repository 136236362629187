/* eslint-disable react/prop-types */

export function Hero ({ subHeading, heading1, heading2, meta }) {
  return (
    <div className="w-full max-w-[600px] mx-auto pt-8">
      <p className="uppercase text-[10px] lg:text-xs font-medium mb-2 lg:mb-4 text-center text-gray-600">
        {subHeading}
      </p>
      <h1 className="text-3xl lg:text-5xl font-noto font-light text-center mb-6 text-gray-950">
        <span className="text-4xl lg:text-6xl">{heading1}</span><br/>{heading2}
      </h1>
      <p className="text-xs lg:text-base font-normal text-center text-gray-500">
        {meta}
      </p>
    </div>
  )
}
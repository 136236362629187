/* eslint-disable react/prop-types */
import { HeadingLargeMobile } from "@/components/common/Heading"
import { Result } from "./elements/Result"
import { useFormData } from "@/context/DataContext"
import { useLocation } from "react-router-dom"
import { useProgress } from "@/lib/useProgress"

export function SearchLaSoResult ({setProgress}) {
  
  useProgress(setProgress)

  const { formData } = useFormData()
  //console.log("Form data:", formData)
  const location = useLocation()
  const { dataInput } = location.state || {}
  //console.log("Data from form file:", dataInput);
  return(
    <div className="md:ml-[88px] flex justify-center">
      <div className="px-4 pt-4 md:pt-12 flex flex-col gap-4 w-full max-w-[992px]">
        <HeadingLargeMobile size="text-2xl" text="An lá số Bát Tự" align="text-left" />
        <Result formData={formData} dataInput={dataInput}/>
      </div>
    </div>
  )
}
/* eslint-disable react/prop-types */
import { Badge } from "@/components/ui/badge"

export function DetailAuthor ({time, tag}) {
  return (
    <div className="flex items-center gap-3">
      <img src="/TienDat.svg" alt="avatar" className="w-12 h-12 rounded-full bg-gray-100" />
      <div>
        <p className="text-sm uppercase font-semibold text-gray-900 mb-1">Phong Thuỷ Từ Tâm</p>
        <div className="flex gap-1">
          <Badge variant="secondary" className="w-fit">
            {tag}
          </Badge>
          <Badge variant="secondary" className="w-fit">{time}</Badge>
        </div>
      </div> 
    </div>
  )
}
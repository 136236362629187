import { createContext, useContext, useState } from 'react'

// Create the FormDataContext
const FormDataContext = createContext();

// Create a provider component
// eslint-disable-next-line react/prop-types
export const FormDataProvider = ({ children }) => {
  const [formData, setFormData] = useState(null);

  return (
    <FormDataContext.Provider value={{ formData, setFormData }}>
      {children}
    </FormDataContext.Provider>
  );
};

// Create a custom hook to consume the context
// eslint-disable-next-line react-refresh/only-export-components
export const useFormData = () => {
  return useContext(FormDataContext);
}

export { FormDataContext }

import { Button } from "@/components/ui/button"
import { Link } from "react-router-dom"

export function NoData () {
  return (
    <div className="h-screen w-full">
      <p className="mb-4 text-gray-800">Đang không có thông tin bạn tìm kiếm.</p>
      <Button>
        <Link to="/an-la-so-bat-tu">Lập lá số khác</Link>
      </Button>
    </div>
  )
}
import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { RiHome3Fill, RiHome3Line, RiAlipayFill, RiAlipayLine, RiBook2Fill, RiBook2Line, RiShieldUserFill, RiShieldUserLine } from "react-icons/ri"

export const NavBarMobile = () => {
  const { pathname } = useLocation()
  const [activeTab, setActiveTab] = useState(pathname)

  useEffect(() => { setActiveTab(pathname) }, [pathname])

  const handleTabClick = (path) => {
    setActiveTab(path)
  }

  return (
    <div className="fixed bottom-0 left-0 w-full md:hidden bg-white border-t border-gray-200 flex justify-between px-5">
      <NavItem
        iconOutline={<RiHome3Line size={24} />}
        iconSolid={<RiHome3Fill size={24} />}
        active={activeTab === "/"}
        onClick={() => handleTabClick("/")}
        to="/"
      />
      <NavItem
        iconOutline={<RiAlipayLine size={24} />}
        iconSolid={<RiAlipayFill size={24} />}
        active={pathname.startsWith("/an-la-so-bat-tu")}
        onClick={() => handleTabClick("/an-la-so-bat-tu")}
        to="/an-la-so-bat-tu"
      />
      <NavItem
        iconOutline={<RiBook2Line size={24} />}
        iconSolid={<RiBook2Fill size={24} />}
        active={pathname.startsWith("/bai-viet")}
        onClick={() => handleTabClick("/bai-viet")}
        to="/bai-viet"
      />
      <NavItem
        iconOutline={<RiShieldUserLine size={24} />}
        iconSolid={<RiShieldUserFill size={24} />}
        active={pathname.startsWith("/gioi-thieu")}
        onClick={() => handleTabClick("/gioi-thieu")}
        to="/gioi-thieu"
      />
    </div>
  );
};

// eslint-disable-next-line react/prop-types
const NavItem = ({ iconOutline, iconSolid, active, onClick, to }) => {
  return (
    <Link 
    to={to} 
    className={`flex flex-col items-center focus:outline-none w-full
    ${active ? "text-gray-700" : "text-gray-500"}`}>
      <button className="w-full flex justify-center py-4" onClick={onClick}>
        {active ? iconSolid : iconOutline}
      </button>
    </Link>
  );
};
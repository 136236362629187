/* eslint-disable react/prop-types */
import { ResultInfoUser } from "./ResultInfoUser"
import { RiShieldUserLine } from "react-icons/ri"
import { TruMenhElement } from "./TruMenhElement"
import { DaiVanElement } from "./DaiVanElement"
import { getCanColorClass, getDiachiDetails } from "./CompareFunction"
import { NoData } from "../NoData"
import { motion } from "framer-motion"

// eslint-disable-next-line react/prop-types
export function Result ({ formData, dataInput }) {
  const { daiVanBatTu, truMenhBatTu } = formData
  if (!daiVanBatTu || daiVanBatTu.length === 0) {
    return <NoData />;
  }
  const { 
    day_thien_can,
    day_dia_chi, 
    day_dia_chi_thap_than_1,
    day_dia_chi_thap_than_2, 
    day_dia_chi_thap_than_3,
    month_thien_can, 
    month_dia_chi, 
    month_thien_can_thap_than, 
    month_dia_chi_thap_than_1,
    month_dia_chi_thap_than_2, 
    month_dia_chi_thap_than_3,
    year_thien_can, 
    year_dia_chi, 
    year_thien_can_thap_than, 
    year_dia_chi_thap_than_1,
    year_dia_chi_thap_than_2, 
    year_dia_chi_thap_than_3,
    hour_thien_can, 
    hour_dia_chi, 
    hour_thien_can_thap_than, 
    hour_dia_chi_thap_than_1,
    hour_dia_chi_thap_than_2, 
    hour_dia_chi_thap_than_3,
   } = truMenhBatTu

   const daiVanBatTuV1 = daiVanBatTu.sort((a, b) => a.yearOld - b.yearOld)

   const year_thien_can_color = getCanColorClass(year_thien_can)
   const month_thien_can_color = getCanColorClass(month_thien_can)
   const day_thien_can_color = getCanColorClass(day_thien_can)
   const hour_thien_can_color = getCanColorClass(hour_thien_can)
   const year_dia_chi_color = getDiachiDetails(year_dia_chi).diachi_color
   const month_dia_chi_color = getDiachiDetails(month_dia_chi).diachi_color
   const day_dia_chi_color = getDiachiDetails(day_dia_chi).diachi_color
   const hour_dia_chi_color = getDiachiDetails(hour_dia_chi).diachi_color
   const year_dia_chi_thap_than_1_color = getDiachiDetails(year_dia_chi).text_1
   const year_dia_chi_thap_than_2_color = getDiachiDetails(year_dia_chi).text_2
   const year_dia_chi_thap_than_3_color = getDiachiDetails(year_dia_chi).text_3
   const month_dia_chi_thap_than_1_color = getDiachiDetails(month_dia_chi).text_1
   const month_dia_chi_thap_than_2_color = getDiachiDetails(month_dia_chi).text_2
   const month_dia_chi_thap_than_3_color = getDiachiDetails(month_dia_chi).text_3
   const day_dia_chi_thap_than_1_color = getDiachiDetails(day_dia_chi).text_1
   const day_dia_chi_thap_than_2_color = getDiachiDetails(day_dia_chi).text_2
   const day_dia_chi_thap_than_3_color = getDiachiDetails(day_dia_chi).text_3
   const hour_dia_chi_thap_than_1_color = getDiachiDetails(hour_dia_chi).text_1
   const hour_dia_chi_thap_than_2_color = getDiachiDetails(hour_dia_chi).text_2
   const hour_dia_chi_thap_than_3_color = getDiachiDetails(hour_dia_chi).text_3

  return (
    <div className="mb-20">
      <ResultInfoUser 
        name={dataInput.name}
        year={dataInput.year}
        month={dataInput.month}
        day={dataInput.day}
        hour={dataInput.hour}
        gender={dataInput.gender}
      />
      <p className="text-center text-lg text-gray-900 font-noto2 font-medium mb-1">Trụ Mệnh</p>
      <motion.div 
        className="grid grid-cols-2 lg:grid-cols-4 gap-2"
        initial={{ 
          opacity: 0, 
          scale: 0.5
        }}
        animate={{ 
          opacity: 1, 
          scale: 1,
          transition: { type: "spring", duration: 1 }
        }}
      >
        <TruMenhElement
          title="Năm"
          thien_can={year_thien_can}
          thien_can_color={year_thien_can_color}
          thien_can_thap_than_color={year_thien_can_color}
          thien_can_thap_than={year_thien_can_thap_than}
          dia_chi={year_dia_chi}
          dia_chi_color={year_dia_chi_color}
          dia_chi_thap_than_1={year_dia_chi_thap_than_1}
          dia_chi_thap_than_2={year_dia_chi_thap_than_2}
          dia_chi_thap_than_3={year_dia_chi_thap_than_3}
          dia_chi_thap_than_1_color={year_dia_chi_thap_than_1_color}
          dia_chi_thap_than_2_color={year_dia_chi_thap_than_2_color}
          dia_chi_thap_than_3_color={year_dia_chi_thap_than_3_color}
        />

        <TruMenhElement
          title="Tháng"
          thien_can={month_thien_can}
          thien_can_color={month_thien_can_color}
          thien_can_thap_than={month_thien_can_thap_than}
          thien_can_thap_than_color={month_thien_can_color}
          dia_chi={month_dia_chi}
          dia_chi_color={month_dia_chi_color}
          dia_chi_thap_than_1={month_dia_chi_thap_than_1}
          dia_chi_thap_than_2={month_dia_chi_thap_than_2}
          dia_chi_thap_than_3={month_dia_chi_thap_than_3}
          dia_chi_thap_than_1_color={month_dia_chi_thap_than_1_color}
          dia_chi_thap_than_2_color={month_dia_chi_thap_than_2_color}
          dia_chi_thap_than_3_color={month_dia_chi_thap_than_3_color}
        />

        <TruMenhElement
          title="Ngày"
          thien_can={day_thien_can}
          thien_can_color={day_thien_can_color}
          icon_nhat_chu={<RiShieldUserLine size={20}/>}
          thien_can_thap_than_color={day_thien_can_color}
          dia_chi={day_dia_chi}
          dia_chi_color={day_dia_chi_color}
          dia_chi_thap_than_1={day_dia_chi_thap_than_1}
          dia_chi_thap_than_2={day_dia_chi_thap_than_2}
          dia_chi_thap_than_3={day_dia_chi_thap_than_3}
          dia_chi_thap_than_1_color={day_dia_chi_thap_than_1_color}
          dia_chi_thap_than_2_color={day_dia_chi_thap_than_2_color}
          dia_chi_thap_than_3_color={day_dia_chi_thap_than_3_color}
        />

        <TruMenhElement
          title="Giờ"
          thien_can={hour_thien_can}
          thien_can_color={hour_thien_can_color}
          thien_can_thap_than={hour_thien_can_thap_than}
          thien_can_thap_than_color={hour_thien_can_color}
          dia_chi={hour_dia_chi}
          dia_chi_color={hour_dia_chi_color}
          dia_chi_thap_than_1={hour_dia_chi_thap_than_1}
          dia_chi_thap_than_2={hour_dia_chi_thap_than_2}
          dia_chi_thap_than_3={hour_dia_chi_thap_than_3}
          dia_chi_thap_than_1_color={hour_dia_chi_thap_than_1_color}
          dia_chi_thap_than_2_color={hour_dia_chi_thap_than_2_color}
          dia_chi_thap_than_3_color={hour_dia_chi_thap_than_3_color}
        />
      </motion.div>

      <p className="text-center text-lg text-gray-900 font-noto2 font-medium mt-4 mb-1">Đại Vận</p>
      <motion.div 
        className="grid grid-cols-2 lg:grid-cols-4 gap-2 w-full"
        initial={{ 
          opacity: 0, 
          scale: 0.5
        }}
        animate={{ 
          opacity: 1, 
          scale: 1,
          transition: { type: "spring", duration: 1 }
        }}
        >
        <DaiVanElement
          year={daiVanBatTuV1[0].yearOld.toString()}
          age={daiVanBatTuV1[0].yearNumber.toString()}
          thien_can={daiVanBatTuV1[0].thienCan}
          dia_chi={daiVanBatTuV1[0].diaChi}
          thien_can_thap_than={daiVanBatTuV1[0].thienCanThapThan}
          dia_chi_thap_than_1={daiVanBatTuV1[0].diaChiThapThan1}
          dia_chi_thap_than_2={daiVanBatTuV1[0].diaChiThapThan2}
          dia_chi_thap_than_3={daiVanBatTuV1[0].diaChiThapThan3}
          thien_can_color={getCanColorClass(daiVanBatTuV1[0].thienCan)}
          dia_chi_color={getDiachiDetails(daiVanBatTuV1[0].diaChi)?.diachi_color}
          thien_can_thap_than_color={getCanColorClass(daiVanBatTuV1[0].thienCan)}
          dia_chi_thap_than_1_color={getDiachiDetails(daiVanBatTuV1[0].diaChi)?.text_1}
          dia_chi_thap_than_2_color={getDiachiDetails(daiVanBatTuV1[0].diaChi)?.text_2}
          dia_chi_thap_than_3_color={getDiachiDetails(daiVanBatTuV1[0].diaChi)?.text_3}
        />

        <DaiVanElement
          year={daiVanBatTuV1[1].yearOld.toString()}
          age={daiVanBatTuV1[1].yearNumber.toString()}
          thien_can={daiVanBatTuV1[1].thienCan}
          dia_chi={daiVanBatTuV1[1].diaChi}
          thien_can_thap_than={daiVanBatTuV1[1].thienCanThapThan}
          dia_chi_thap_than_1={daiVanBatTuV1[1].diaChiThapThan1}
          dia_chi_thap_than_2={daiVanBatTuV1[1].diaChiThapThan2}
          dia_chi_thap_than_3={daiVanBatTuV1[1].diaChiThapThan3}
          thien_can_color={getCanColorClass(daiVanBatTuV1[1].thienCan)}
          dia_chi_color={getDiachiDetails(daiVanBatTuV1[1].diaChi)?.diachi_color}
          thien_can_thap_than_color={getCanColorClass(daiVanBatTuV1[1].thienCan)}
          dia_chi_thap_than_1_color={getDiachiDetails(daiVanBatTuV1[1].diaChi)?.text_1}
          dia_chi_thap_than_2_color={getDiachiDetails(daiVanBatTuV1[1].diaChi)?.text_2}
          dia_chi_thap_than_3_color={getDiachiDetails(daiVanBatTuV1[1].diaChi)?.text_3}
        />

        <DaiVanElement
          year={daiVanBatTuV1[2].yearOld.toString()}
          age={daiVanBatTuV1[2].yearNumber.toString()}
          thien_can={daiVanBatTuV1[2].thienCan}
          dia_chi={daiVanBatTuV1[2].diaChi}
          thien_can_thap_than={daiVanBatTuV1[2].thienCanThapThan}
          dia_chi_thap_than_1={daiVanBatTuV1[2].diaChiThapThan1}
          dia_chi_thap_than_2={daiVanBatTuV1[2].diaChiThapThan2}
          dia_chi_thap_than_3={daiVanBatTuV1[2].diaChiThapThan3}
          thien_can_color={getCanColorClass(daiVanBatTuV1[2].thienCan)}
          dia_chi_color={getDiachiDetails(daiVanBatTuV1[2].diaChi)?.diachi_color}
          thien_can_thap_than_color={getCanColorClass(daiVanBatTuV1[2].thienCan)}
          dia_chi_thap_than_1_color={getDiachiDetails(daiVanBatTuV1[2].diaChi)?.text_1}
          dia_chi_thap_than_2_color={getDiachiDetails(daiVanBatTuV1[2].diaChi)?.text_2}
          dia_chi_thap_than_3_color={getDiachiDetails(daiVanBatTuV1[2].diaChi)?.text_3}
        />

        <DaiVanElement
          year={daiVanBatTuV1[3].yearOld.toString()}
          age={daiVanBatTuV1[3].yearNumber.toString()}
          thien_can={daiVanBatTuV1[3].thienCan}
          dia_chi={daiVanBatTuV1[3].diaChi}
          thien_can_thap_than={daiVanBatTuV1[3].thienCanThapThan}
          dia_chi_thap_than_1={daiVanBatTuV1[3].diaChiThapThan1}
          dia_chi_thap_than_2={daiVanBatTuV1[3].diaChiThapThan2}
          dia_chi_thap_than_3={daiVanBatTuV1[3].diaChiThapThan3}
          thien_can_color={getCanColorClass(daiVanBatTuV1[3].thienCan)}
          dia_chi_color={getDiachiDetails(daiVanBatTuV1[3].diaChi)?.diachi_color}
          thien_can_thap_than_color={getCanColorClass(daiVanBatTuV1[3].thienCan)}
          dia_chi_thap_than_1_color={getDiachiDetails(daiVanBatTuV1[3].diaChi)?.text_1}
          dia_chi_thap_than_2_color={getDiachiDetails(daiVanBatTuV1[3].diaChi)?.text_2}
          dia_chi_thap_than_3_color={getDiachiDetails(daiVanBatTuV1[3].diaChi)?.text_3}
        />

        <DaiVanElement
          year={daiVanBatTuV1[4].yearOld.toString()}
          age={daiVanBatTuV1[4].yearNumber.toString()}
          thien_can={daiVanBatTuV1[4].thienCan}
          dia_chi={daiVanBatTuV1[4].diaChi}
          thien_can_thap_than={daiVanBatTuV1[4].thienCanThapThan}
          dia_chi_thap_than_1={daiVanBatTuV1[4].diaChiThapThan1}
          dia_chi_thap_than_2={daiVanBatTuV1[4].diaChiThapThan2}
          dia_chi_thap_than_3={daiVanBatTuV1[4].diaChiThapThan3}
          thien_can_color={getCanColorClass(daiVanBatTuV1[4].thienCan)}
          dia_chi_color={getDiachiDetails(daiVanBatTuV1[4].diaChi)?.diachi_color}
          thien_can_thap_than_color={getCanColorClass(daiVanBatTuV1[4].thienCan)}
          dia_chi_thap_than_1_color={getDiachiDetails(daiVanBatTuV1[4].diaChi)?.text_1}
          dia_chi_thap_than_2_color={getDiachiDetails(daiVanBatTuV1[4].diaChi)?.text_2}
          dia_chi_thap_than_3_color={getDiachiDetails(daiVanBatTuV1[4].diaChi)?.text_3}
        />

        <DaiVanElement
          year={daiVanBatTuV1[5].yearOld.toString()}
          age={daiVanBatTuV1[5].yearNumber.toString()}
          thien_can={daiVanBatTuV1[5].thienCan}
          dia_chi={daiVanBatTuV1[5].diaChi}
          thien_can_thap_than={daiVanBatTuV1[5].thienCanThapThan}
          dia_chi_thap_than_1={daiVanBatTuV1[5].diaChiThapThan1}
          dia_chi_thap_than_2={daiVanBatTuV1[5].diaChiThapThan2}
          dia_chi_thap_than_3={daiVanBatTuV1[5].diaChiThapThan3}
          thien_can_color={getCanColorClass(daiVanBatTuV1[5].thienCan)}
          dia_chi_color={getDiachiDetails(daiVanBatTuV1[5].diaChi)?.diachi_color}
          thien_can_thap_than_color={getCanColorClass(daiVanBatTuV1[5].thienCan)}
          dia_chi_thap_than_1_color={getDiachiDetails(daiVanBatTuV1[5].diaChi)?.text_1}
          dia_chi_thap_than_2_color={getDiachiDetails(daiVanBatTuV1[5].diaChi)?.text_2}
          dia_chi_thap_than_3_color={getDiachiDetails(daiVanBatTuV1[5].diaChi)?.text_3}
        />

        <DaiVanElement
          year={daiVanBatTuV1[6].yearOld.toString()}
          age={daiVanBatTuV1[6].yearNumber.toString()}
          thien_can={daiVanBatTuV1[6].thienCan}
          dia_chi={daiVanBatTuV1[6].diaChi}
          thien_can_thap_than={daiVanBatTuV1[6].thienCanThapThan}
          dia_chi_thap_than_1={daiVanBatTuV1[6].diaChiThapThan1}
          dia_chi_thap_than_2={daiVanBatTuV1[6].diaChiThapThan2}
          dia_chi_thap_than_3={daiVanBatTuV1[6].diaChiThapThan3}
          thien_can_color={getCanColorClass(daiVanBatTuV1[6].thienCan)}
          dia_chi_color={getDiachiDetails(daiVanBatTuV1[6].diaChi)?.diachi_color}
          thien_can_thap_than_color={getCanColorClass(daiVanBatTuV1[6].thienCan)}
          dia_chi_thap_than_1_color={getDiachiDetails(daiVanBatTuV1[6].diaChi)?.text_1}
          dia_chi_thap_than_2_color={getDiachiDetails(daiVanBatTuV1[6].diaChi)?.text_2}
          dia_chi_thap_than_3_color={getDiachiDetails(daiVanBatTuV1[6].diaChi)?.text_3}
        />

        <DaiVanElement
          year={daiVanBatTuV1[7].yearOld.toString()}
          age={daiVanBatTuV1[7].yearNumber.toString()}
          thien_can={daiVanBatTuV1[7].thienCan}
          dia_chi={daiVanBatTuV1[7].diaChi}
          thien_can_thap_than={daiVanBatTuV1[7].thienCanThapThan}
          dia_chi_thap_than_1={daiVanBatTuV1[7].diaChiThapThan1}
          dia_chi_thap_than_2={daiVanBatTuV1[7].diaChiThapThan2}
          dia_chi_thap_than_3={daiVanBatTuV1[7].diaChiThapThan3}
          thien_can_color={getCanColorClass(daiVanBatTuV1[7].thienCan)}
          dia_chi_color={getDiachiDetails(daiVanBatTuV1[7].diaChi)?.diachi_color}
          thien_can_thap_than_color={getCanColorClass(daiVanBatTuV1[7].thienCan)}
          dia_chi_thap_than_1_color={getDiachiDetails(daiVanBatTuV1[7].diaChi)?.text_1}
          dia_chi_thap_than_2_color={getDiachiDetails(daiVanBatTuV1[7].diaChi)?.text_2}
          dia_chi_thap_than_3_color={getDiachiDetails(daiVanBatTuV1[7].diaChi)?.text_3}
        />
      </motion.div>
    </div>
  )
}
/* eslint-disable react/prop-types */
import { HeadingLargeMobile } from "@/components/common/Heading"
import { Sidebar } from "./elements/Sidebar"
import { Articles } from "./elements/Articles"
import { useProgress } from "@/lib/useProgress"

export function BlogTamLyHoc ({setProgress}) {
  useProgress(setProgress)
  return (
    <div className="md:ml-[88px] h-screen">
      <div className="mx-auto max-w-[1392px] w-full h-screen p-4">
        <HeadingLargeMobile size="text-2xl" text="Tâm lý học" align="text-left" visibility="block lg:hidden" margin="mb-4"/>
        <div className="flex flex-col lg:flex-row">
          <Sidebar />
          <Articles title="Tâm lý học"/>
        </div>
      </div>
    </div>
  )
}
import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { RiHome3Fill, RiAlipayFill, RiBook2Fill, RiShieldUserFill } from "react-icons/ri";

export function NavBarWeb() {
  const { pathname } = useLocation()
  const [activeTab, setActiveTab] = useState(pathname)

  useEffect(() => {setActiveTab(pathname)}, [pathname])

  const handleTabClick = (path) => {
    setActiveTab(path)
  }

  return (
    <div className="fixed px-[6px] py-4 w-[88px] border-r-[1px] h-screen hidden md:flex flex-col items-center gap-4 bg-white z-10">
      <Link to="/">
        <img src="/logo.svg" alt="logo" className="w-10" />
      </Link>

      <NavLink 
      icon={<RiHome3Fill size={24} />} 
      to="/" 
      label="Trang chủ" 
      active={activeTab === "/"} 
      onClick={() => handleTabClick("/")} />

      <NavLink 
      icon={<RiAlipayFill size={24} color="#d4d4d8" />} 
      to="/an-la-so-bat-tu" 
      label="An lá số" 
      active={pathname.startsWith("/an-la-so-bat-tu")} 
      onClick={() => handleTabClick("/an-la-so-bat-tu")} />

      <NavLink 
      icon={<RiBook2Fill size={24} color="#d4d4d8" />} 
      to="/bai-viet" 
      label="Bài viết" 
      active={pathname.startsWith("/bai-viet")} 
      onClick={() => handleTabClick("/bai-viet")} />

      <NavLink 
      icon={<RiShieldUserFill size={24} color="#d4d4d8" />} 
      to="/gioi-thieu" 
      label="Giới thiệu" 
      active={pathname.startsWith("/gioi-thieu")} 
      onClick={() => handleTabClick("/gioi-thieu")} />
    </div>
  );
}

// eslint-disable-next-line react/prop-types
const NavLink = ({ icon, to, label, active, onClick }) => {
  const iconColor = active ? "#000000" : "#d4d4d8";
  const textColor = active ? "#000000" : "#8c8c8c";
  const fontWeight = active ? "semibold" : "normal";

  return (
    <Link to={to} onClick={onClick} className="flex flex-col w-full items-center bg-white hover:bg-gray-100 p-2 rounded-md">
      {React.cloneElement(icon, { color: iconColor })} {/* Update icon color */}
      <p className={`text-xs font-${fontWeight} text-gray-900`} style={{ color: textColor }}>{label}</p> {/* Update text color and font weight */}
    </Link>
  );
};

/* eslint-disable react/prop-types */
import { HeadingLargeMobile } from "@/components/common/Heading"
import { AnLaSoForm } from "./elements/Form"
import { useProgress } from "@/lib/useProgress"

export function SearchLaSo ({ setProgress }) {
  
  useProgress(setProgress)
  
  return(
    <div className="md:ml-[88px] flex justify-center">
      <div className="px-4 pt-4 md:pt-12 flex flex-col gap-4 w-full md:max-w-[360px] mb-40">
        <HeadingLargeMobile size="text-2xl" text="An lá số Bát Tự" align="text-left"/>
        <p className="mb-4 text-sm text-gray-700">Vui lòng nhập ngày sinh dương lịch.</p>
        <AnLaSoForm />
      </div>
    </div>
  )
}
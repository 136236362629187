/* eslint-disable react/prop-types */
import { Values } from "./elements/Values"
import { Hero } from "./elements/Hero"
import { useProgress } from "@/lib/useProgress"
import { motion } from "framer-motion"

export function AboutUs ({setProgress}) {
  useProgress(setProgress)
  return (
    <div className="md:ml-[88px] h-screen">
      <Hero 
        subHeading="giới thiệu"
        heading1="Bát tự - Tử Bình"
        heading2="hiểu mình hiểu người"
      />
      <motion.img 
        src="https://phongthuytutam.s3.ap-southeast-2.amazonaws.com/Team-Building.svg" alt="img" 
        className="w-80 lg:w-[400px] mx-auto mb-6 lg:mb-10"
        initial={{ 
          opacity: 0, 
          scale: 0.5, 
          filter: "blur(16px)"
        }}
        animate={{ 
          opacity: 1, 
          scale: 1, 
          filter: "blur(0px)" ,
          transition: { type: "spring", duration: 1 }
        }}
      />
      <h2 className="mx-auto text-center text-2xl lg:text-4xl font-noto mb-4 lg:mb-6 text-gray-900">
        Giá trị cốt lõi
      </h2>
      <Values />
    </div>
  )
}
/* eslint-disable react/prop-types */
import { Article } from "./Article"

export function Articles ({data, heading}) {

  return (
    <div className="lg:ml-[200px] w-full lg:px-8 flex flex-col gap-4">
      <h1 className="font-noto2 text-gray-900 text-lg md:text-xl">
        {heading}
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {data.map((dataItem, index) => (
          <Article
            key={index}
            slug={dataItem.slug}
            img={dataItem.img}
            title={dataItem.title}
            meta={dataItem.meta}
            tag={dataItem.tag}
            date={dataItem.date}
          />
        ))}
      </div>
    </div>
  )
}
/* eslint-disable react/prop-types */
export function DetailContent ({img, heading, text, metaLink}) {
  const selectionText = "selection:bg-lime-100 selection:text-lime-800"
  return (
    <div>
      <h3 className={`mb-4 text-gray-900 font-noto2 font-medium text-xl ${selectionText}`}>{heading}</h3>
      <img src={img} alt="img" className="rounded-xl mb-4" />
      <p className={`text-base text-gray-800 mb-4 ${selectionText}`}>{text}</p>
      <p>{metaLink}</p>
    </div>
  )
}
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import { RiShieldUserLine } from "react-icons/ri"
import { PiDot } from "react-icons/pi"
import { Button } from "@/components/ui/button"
import { Link } from "react-router-dom"
import { hours } from "./Form"

export function ResultInfoUser ({ name, day, month, hour, year, gender }) {
  const hourString = hour.toString()
  const hourLabel = hours.find(item => item.value === hourString)?.label || hourString
  const genderLabel = gender === "male" ? "nam" : "nữ"

  return(
    <div className=" px-4 py-3 rounded-md border border-gray-300 flex flex-col md:flex-row justify-between items-start md:items-end gap-2 bg-white mb-4"
    >
      <div>
        <div className="flex gap-2 items-center mb-2">
          <RiShieldUserLine size={24} />
          <p className="text-xl font-noto2 font-normal">{name}</p>
        </div>
        <div className="flex items-center">
          <p className="text-gray-500 text-sm font-normal">Ngày {day}/ {month}/ {year}</p>
          <PiDot />
          <p className="text-gray-500 text-sm font-normal">Giờ {hourLabel}</p>
          <PiDot />
          <p className="text-gray-500 text-sm font-normal">Giới tính {genderLabel}</p>
        </div>
      </div>
      <Button className="w-full md:w-fit" variant="outline">
        <Link to="/an-la-so-bat-tu">Lập lá khác</Link>
      </Button>
    </div>
  )
}
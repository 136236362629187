/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable react-refresh/only-export-components */
import { useEffect } from "react"

export function useProgress(setProgress) {
  useEffect(() => {
    setProgress(30)
    setTimeout(() => {
      setProgress(100)
    }, 500)
  }, [setProgress])
}

/* eslint-disable react/prop-types */
import { FacebookShareButton, LinkedinShareButton } from "react-share";
import { FaFacebookMessenger,FaFacebook,FaLinkedin } from "react-icons/fa";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip"

export function ShareButtons ({shareUrl}) {
  return (
    <div className="flex gap-3">
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger>
            <FacebookShareButton url={shareUrl}>
              <FaFacebook size={24} color="gray"/>
            </FacebookShareButton>
          </TooltipTrigger>
          <TooltipContent>
            <p>Chia sẻ facebook</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>

      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger>
            <FacebookShareButton url={shareUrl}>
              <FaFacebookMessenger size={24} color="gray"/>
            </FacebookShareButton>
          </TooltipTrigger>
          <TooltipContent>
            <p>Chia sẻ messenger</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>

      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger>
            <LinkedinShareButton>
              <FaLinkedin size={24} color="gray"/>
            </LinkedinShareButton>
          </TooltipTrigger>
          <TooltipContent>
            <p>Chia sẻ linkedin</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </div>
  )
}
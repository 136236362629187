import { HeadingLargeMobile } from "@/components/common/Heading"

export function Courses () {
  return(
    <div className="p-4 flex flex-col gap-4">
      <HeadingLargeMobile size="text-xl" text="Tôi tự học" />
      <div className="flex flex-col items-center h-full my-10 gap-3">
        <img src="/Nothing.svg" alt="" className="w-[160px]" />
        <p className="text-center text-gray-800">Đang phát triển</p>
      </div>
    </div>
  )
}
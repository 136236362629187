import { Link, useLocation } from "react-router-dom"
import { useState } from "react"

export function SidebarContent () {
  const location = useLocation()
  const [activeLink, setActiveLink] = useState(location.pathname)
  const handleSetActiveLink = (path) => setActiveLink(path)

  return(
    <div className="py-4 flex flex-row lg:flex-col gap-4 whitespace-nowrap">
      <Link 
      to="/bai-viet" 
      className={`block text-[15px] hover:underline hover:text-gray-900 
      ${activeLink === '/bai-viet' ? 'font-medium text-gray-900' : 'font-normal text-gray-400'}`} 
      onClick={() => handleSetActiveLink('/bai-viet')}>
        Mới nhất
      </Link>

      <Link 
      to="/bai-viet/bat-tu" 
      className={`block text-[15px] hover:underline hover:text-gray-900 
      ${activeLink === '/bai-viet/bat-tu' ? 'font-medium text-gray-900' : 'font-normal text-gray-400'}`} 
      onClick={() => handleSetActiveLink('/bai-viet/bat-tu')}>
        Bát Tự Tử Bình
      </Link>

      <Link 
      to="/bai-viet/phong-thuy" 
      className={`block text-[15px] hover:underline hover:text-gray-900 
      ${activeLink === '/bai-viet/phong-thuy' ? 'font-medium text-gray-900' : 'font-normal text-gray-400'}`} 
      onClick={() => handleSetActiveLink('/bai-viet/phong-thuy')}>
        Phong Thuỷ Dương Trạch
      </Link>

      <Link 
      to="/bai-viet/tam-ly-hoc" 
      className={`block text-[15px] hover:underline hover:text-gray-900 
      ${activeLink === '/bai-viet/tam-ly-hoc' ? 'font-medium text-gray-900' : 'font-normal text-gray-400'}`} 
      onClick={() => handleSetActiveLink('/bai-viet/tam-ly-hoc')}>
        Tâm lý học
      </Link>
    </div>
  )
}
/* eslint-disable react/prop-types */
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbList, BreadcrumbPage, BreadcrumbSeparator } from "@/components/ui/breadcrumb"
import { Link, useParams } from "react-router-dom"
import { ShareButtons } from "./DetailShare"
import { useLocation } from 'react-router-dom'
import { DetailAuthor } from "./DetailAuthor"
import { DetailContent } from "./DetailContent"
import { useEffect, useState } from 'react'
import axios from 'axios'
import { BASE_URL } from "@/config"
import { useProgress } from "@/lib/useProgress"
import { motion } from "framer-motion"

export function Detail ({setProgress}) {

  useProgress(setProgress)
  // for share buttons
  const location = useLocation()
  const shareUrl = window.location.origin + location.pathname

  // call APIs
  const { slug } = useParams()
  const [postData, setPostData] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/post/${slug}`)
        setPostData(response.data)
        setLoading(false)
      } catch (error) {
        console.error('Error fetching post data:', error)
        setLoading(false)
      }
    }

    fetchData()
  }, [slug])

  if (loading) {
    return <div>Loading...</div>
  }

  if (!postData) {
    return <div>No data found for slug: {slug}</div>;
  }

  return(
    <div className="md:ml-[88px] h-screen">
      <div className="hidden lg:block lg:p-4 border-b fixed z-10 backdrop-blur-xl w-full">
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink>
                <Link to="/">Trang chủ</Link>
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbLink>
                <Link to="/bai-viet">Bài Viết</Link>
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage>
                {postData.title}
              </BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      </div>
      <div className="p-4 lg:p-6 lg:py-[80px] flex flex-col justify-center w-full mx-auto max-w-[680px]">
        <motion.img 
          src={postData.img} alt="thumbnail" 
          className="rounded-lg w-full mb-4 lg:mb-5" 
          initial={{ 
            opacity: 0, 
            scale: 0.5, 
            filter: "blur(16px)"
          }}
          animate={{ 
            opacity: 1, 
            scale: 1, 
            filter: "blur(0px)" ,
            transition: { type: "spring", duration: 1 }
          }}
        />
        <h2 className="text-center text-2xl lg:text-3xl font-noto2 font-medium text-gray-950 mb-2 lg:mb-3">
          {postData.title}
        </h2>
        <p className="text-center font-normal text-gray-600 text-base">
          {postData.meta}
        </p>
        <div className="flex items-center justify-between py-6 border-b mb-6">
          <DetailAuthor time={postData.time} tag={postData.tag}/>
          <ShareButtons shareUrl={shareUrl}/>
        </div>
        <DetailContent 
          img="/article/thumbnails/google-analytics-alternatives.webp"
          heading="Plausible Analytics"
          text="Plausible stands out for its commitment to privacy and simplicity. It offers a lightweight and open-source web analytics tool that doesn’t use cookies and fully complies with GDPR, making it an attractive option for privacy-focused websites. As far as I remember Plausible and Fathom both are widely used. I believe that Plausible is the first open source google analytics alternatives. I might be wrong."
        />
      </div>
      
    </div>
  )
}
import './App.css'
import { NavBarMobile } from './components/navBar/NavBarMobile'
import { Routes, Route } from "react-router-dom"
import { SearchLaSo } from './views/searchLaSo/SearchLaSo'
import { SearchLaSoResult } from './views/searchLaSo/SearchLaSoResult'
import { Blog } from './views/blog/Blog'
import { Courses } from './views/courses/Courses'
import { Home } from './views/homepage/Home'
import { NavBarWeb } from './components/navBar/NavBarWeb'
import { Toaster } from './components/ui/toaster'
import { FormDataProvider } from './context/DataContext'
import { BlogBatTu } from './views/blog/BlogBatTu'
import { Detail } from './views/blog/elements/Detail'
import { AboutUs } from './views/aboutUs/AboutUs'
import { BlogPhongThuy } from './views/blog/BlogPhongThuy'
import { BlogTamLyHoc } from './views/blog/BlogTamLyHoc'
import LoadingBar from 'react-top-loading-bar'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Admin } from './views/admin/Admin'
import { AdminDashBoard } from './views/admin/AdminDashboard'

function App() {
  const [progress, setProgress] = useState(0)
  const location = useLocation()
  const isAdminRoute = location.pathname.startsWith('/admin')

  return (
    <FormDataProvider>
      <div>
        <LoadingBar
          color='#65a30d'
          progress={progress}
          shadow= {true}
          shadowStyle={{ boxShadow: '0px 2px 8px 0px rgba(132, 204, 22, 0.50)' }}
          height={2.5}
          onLoaderFinished={() => setProgress(0)}
        />
        {!isAdminRoute && <NavBarMobile />}
        {!isAdminRoute && <NavBarWeb />}
        <Toaster />
        <Routes>
          <Route path="/" element={<Home setProgress={setProgress} />} />
          <Route path="/an-la-so-bat-tu" element={<SearchLaSo setProgress={setProgress} />} />
          <Route path="/an-la-so-bat-tu/ket-qua" element={<SearchLaSoResult setProgress={setProgress} />} />
          <Route path="/bai-viet" element={<Blog setProgress={setProgress} />} />
          <Route path="/bai-viet/bat-tu" element={<BlogBatTu setProgress={setProgress} />} />
          <Route path="/bai-viet/phong-thuy" element={<BlogPhongThuy setProgress={setProgress} />}/>
          <Route path="/bai-viet/tam-ly-hoc" element={<BlogTamLyHoc setProgress={setProgress} />}/>
          <Route path="/toi-tu-hoc" element={<Courses setProgress={setProgress} />} />
          <Route path="/bai-viet/:slug" element={<Detail setProgress={setProgress} />}/>
          <Route path="/gioi-thieu" element={<AboutUs setProgress={setProgress} />}/>
          <Route path='/admin' element={<Admin setProgress={setProgress} />} />
          <Route path='/admin/dashboard' element={<AdminDashBoard setProgress={setProgress} />} />
        </Routes>
      </div>
    </FormDataProvider>
  )
}

export default App

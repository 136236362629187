/* eslint-disable react/prop-types */
import { Separator } from "@/components/ui/separator"

export function TruMenhElement ({ 
  title, thien_can, thien_can_thap_than, dia_chi, dia_chi_thap_than_1, dia_chi_thap_than_2, dia_chi_thap_than_3, icon_nhat_chu, thien_can_thap_than_color, thien_can_color, dia_chi_color, dia_chi_thap_than_1_color, dia_chi_thap_than_2_color, dia_chi_thap_than_3_color
}) {
  return(
    <div className="flex flex-col gap-2 pb-3 pt-1 px-1 rounded-md border border-gray-300 w-full h-full">
      <div className="bg-gray-100 text-gray-500 py-1 w-full text-xs text-center rounded">
        {title}
      </div>

      <div>
        <div className={`text-sm font-normal text-center flex justify-center ${thien_can_thap_than_color}`}>
          {thien_can_thap_than} {icon_nhat_chu}
        </div>
        <p className={`uppercase text-xl text-center font-noto2 font-extrabold ${thien_can_color}`}>
          {thien_can}
        </p>
        <Separator />
        <p className={`uppercase text-xl text-center font-noto2 font-extrabold ${dia_chi_color}`}>
          {dia_chi}
        </p>
        <div className="text-sm font-normal text-center">
          <p className={dia_chi_thap_than_1_color}>{dia_chi_thap_than_1}</p>
          <p className={dia_chi_thap_than_2_color}>{dia_chi_thap_than_2}</p>
          <p className={dia_chi_thap_than_3_color}>{dia_chi_thap_than_3}</p>
        </div>
      </div>
    </div>
  )
}